<!--
 * @Author: lzh
 * @Date: 2022-07-05 18:07:04
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 17:24:25
 * @Description: file content
-->
<template>
  <div class="edit-offer-box">
    <div class="cell-b">
      <h3>標題：</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>

      <h3>副標題：</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入副標題：-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>
    </div>
    <div class="cell-b">
      <h3>項-標題</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs.itemData[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入項-標題-${lItem.name}`"
          @input="setValue('itemData')"
          type="textarea"
          rows="3"
        ></el-input>
      </div>
    </div>
    <div class="cell-b">
      <h3>項-標題</h3>
      <div
        v-for="(item,index) in configs.itemData.descObj"
        :key="index"
      >
        <div class="cell-b">
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}text`]"
            :placeholder="`請輸入項-標題-${lItem.name}`"
            @input="setValue('itemData')"
            type="textarea"
            rows="3"
          ></el-input>
        </div>
      </div>

    </div>
    <div class="cell-b">
      <h3>圖標</h3>
      <div class="ic-box">

        <div
          v-for="(item,index) in configs.itemData.icons"
          :key="index"
          class="icon"
        >
          <el-button
            icon="el-icon-delete"
            class="delete"
            size="mini"
            circle
            @click="delDom(index)"
          ></el-button>
          <selfUpload v-model="item.img" />
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
            :placeholder="`請輸入-${lItem.name}`"
            type="textarea"
            rows="3"
          />
        </div>
        <div
          class="icon px-add"
          @click="addDom"
        >
          <i class="el-icon-plus"></i>
        </div>
      </div>
    </div>
    <div class="cell-b">
      <h3>選擇背景圖片</h3>
      <selfUpload
        v-model="configs.itemData.img"
        type="default"
        @change="setValue('itemData')"
      />
      <selfCell title="輸入圖片描述">
        <el-input
          v-model="configs.itemData.alt"
          type="textarea"
          @change="setValue('itemData')"
          :placeholder="`輸入圖片描述`"
        />
      </selfCell>
    </div>

    <div class="cell-b">
      <h3>按鈕</h3>
      <div class="title-b">
        <div
          class="left"
          style="width:50%;"
        >
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="configs.itemData.btn[`${lItem.code=='HK'?'':lItem.code}title`]"
            @input="setValue('itemData')"
            :placeholder="`請輸入-${lItem.name}`"
            type="textarea"
            rows="3"
          />
        </div>
        <selfPagePicker
          v-model="configs.itemData.btn.go"
          @onChange="setValue('itemData')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  name: 'edit-offer',
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          title: '',
          desc: '',
          itemData: {
            title: '',
            descObj: [],
            icons: [],
            btnText: '',
            img: '',
          },
        };
      },
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  data () {
    return {
      language: []
    }
  },
  created () {
    this.language = this.$storage.get('language')
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    delDom (index) {
      this.configs.itemData.icons.splice(index, 1);
      this.setValue('itemData');
    },
    addDom () {
      this.configs.itemData.icons.push({ img: require('../../assets/material/首頁/Group 41@2x.png'), title: "預約演示" });
      this.setValue('itemData');
    }
  },
};
</script>

<style lang="less" scoped>
.edit-offer-box {
  .cell-b {
    box-shadow: 0 2px 6px #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    .ic-box {
      display: flex;
      flex-wrap: wrap;
      .icon {
        width: 33%;
        margin-top: 10px;
        position: relative;
        & /deep/ .el-upload {
          vertical-align: middle;
        }
        .delete {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 10;
          &:hover {
            background-color: var(--themeColor);
            color: #fff;
          }
        }
      }
      .px-add {
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #efefef;
          border-radius: 6px;
        }
      }
    }
  }
  .title-b {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h3 {
      word-break: keep-all;
    }
  }
}
</style>
